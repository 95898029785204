<mdb-navbar #navbar class="bar-cor" SideClass="navbar navbar-expand-lg navbar-dark danger-color-dark darken-2 justify-content-between fixed-top" [containerInside]="true">
  <mdb-navbar-brand>
      <a class="navbar-brand ml-10" href="https://clubb2b.com.br">
          <img src="https://clubb2b.com.br/images/main/clubb2b.png" height="40" alt="clubb2b" mdbTooltip="ClubB2B, sua StartUp de Negócios. Clique aqui para acessar o site.">
      </a>
  </mdb-navbar-brand>

  <links>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" mdbTooltip="Vai para página da ClubB2B">
        <a class="nav-link waves-light" href="https://clubb2b.com.br" mdbWavesEffect>Visite á ClubB2B<span class="sr-only">(current)</span></a>
      </li>
    </ul>
  </links>

</mdb-navbar>

<div class="container-fluid full-page-container bg-img">
  <div class="row h-100 d-md-flex justify-content-center align-items-center overflow-auto">
    <div class="col-md-8 text-center pt-5 fadeIn animated">
      <div *ngIf="innerWidth > 414">
        <img src="https://flasocial.com.br/images/flasocial.png" class="img-fluid rounded" style="width: 120px; height: 120px" alt="Flasocial" mdbTooltip="FlaSocial, a Rede Social do mais amado do Mundo.">
        <h1 class="text-dark app-heading mb-4" mdbTooltip="Vamos Flamengo, vamos ser campeão!!!">
          <a class="font-weight-bold sombra-dark" style="color: #6D120E;">Fla</a>
          <a class="font-weight-bold black-text sombra-red">Social</a>
        </h1>
        <h2 class="text-warning app-heading mb-4 sombra-dark" mdbTooltip="Agora o Mundo Rubro Negro possui a sua Rede Social de Flanáticos!!!">
          A Rede Social dos 
          <a class="font-weight-bold sombra" style="color: #6D120E;">Fla</a>náticos
        </h2>
      </div>
      <div *ngIf="innerWidth <= 414" class="pt-4 sombra-dark">
        <img src="https://flasocial.com.br/images/flasocial.png" class="img-fluid rounded" style="width: 120px; height: 120px" alt="Flasocial" mdbTooltip="FlaSocial, a Rede Social do mais amado do Mundo.">
        <h1 class="dislpay-3 text-dark mb-1" mdbTooltip="Vamos Flamengo, vamos ser campeão!!!">
          <a class="font-weight-bold" style="color: #6D120E;">Fla</a>
          <a class="font-weight-bold black-text sombra-red">Social</a>
        </h1>
        <h2 class="display-5 font-weight-bold text-warning mb-1" mdbTooltip="Agora o Mundo Rubro Negro possui a sua Rede Social de Flanáticos!!!">
          A Rede Social dos 
          <a class="display-5 font-weight-bold sombra" style="color: #6D120E;">Fla</a>
          <a class="display-5 font-weight-bold amber-text sombra">náticos</a>
        </h2>
      </div>            
      <h4 class="subtext-header white-text text-justify mt-2 mb-2">
        <p class="font-weight-bold text-center sombra-dark pt-4">
          Em breve estaremos online
        </p>
      </h4>
      <h1 *ngIf="innerWidth > 414" class="display-3 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra-dark">
        SITE EM CONSTRUÇÃO
      </h1>
      <h3 *ngIf="innerWidth <= 414" class="display-5 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra-dark">
        SITE/APP EM CONSTRUÇÃO
      </h3>
      <div *ngIf="innerWidth > 414" class="timer sombra-dark">
        <h4 class="font-weight-bold white-text"> Contagem Regressiva </h4>
        <span id="days" class="font-weight-bold amber-text"> {{daysToDday}} </span>
        <a class="font-weight-bold white-text">Dia(s)</a>
        <span id="hours" class="font-weight-bold amber-text"> {{hoursToDday}} </span>
        <a class="font-weight-bold white-text">Hs</a> 
        <span id="minutes" class="font-weight-bold amber-text"> {{minutesToDday}} </span>
        <a class="font-weight-bold white-text">Min</a>
        <span id="seconds" class="font-weight-bold amber-text"> {{secondsToDday}} </span>
        <a class="font-weight-bold white-text">Seg</a>
      </div>
      <div *ngIf="innerWidth <= 414" class="timer-mobile sombra-dark pb-3">
        <h4 class="font-weight-bold white-text"> Contagem Regressiva </h4>
        <span id="days" class="font-weight-bold amber-text"> {{daysToDday}} </span>
        <a class="font-weight-bold white-text">Dia(s)</a>
        <span id="hours" class="font-weight-bold amber-text"> {{hoursToDday}} </span>
        <a class="font-weight-bold white-text">Hs</a> 
        <span id="minutes" class="font-weight-bold amber-text"> {{minutesToDday}} </span>
        <a class="font-weight-bold white-text">Min</a>
        <span id="seconds" class="font-weight-bold amber-text"> {{secondsToDday}} </span>
        <a class="font-weight-bold white-text">Seg</a>
      </div>      
    </div>
  </div>
</div>
<footer class="footer-copyright text-center text-warning font-weight-bold py-3 elegant-color-dark darken-2 fixed-bottom">
  Copyright 2018
  <a class="white-text font-weight-bold sombra-red">&copy;</a> - 
  <a class="text-white font-weight-bold" href="https://clubb2b.com.br/" mdbTooltip="ClubB2B, sua StartUp de Negócios. Clique aqui para acessar o site.">
    <a class="teal-text font-weight-bold"> Club</a>
    <a class="yellow-text font-weight-bold">B</a>
    <a class="blue-text font-weight-bold">2</a>
    <a class="white-text font-weight-bold">B</a>
  </a>
</footer>